import React, { useContext, useRef } from 'react'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const AboutSection = useRef(null)
  const SpecializationSection = useRef(null)
  const ProjectsSection = useRef(null)
  const ClientsSection = useRef(null)
  const TestimonialsSection = useRef(null)
  const ContactsSection = useRef(null)
  const CareersSection = useRef(null)

  return (
    <AppContext.Provider
      value={{
        AboutSection,
        SpecializationSection,
        ProjectsSection,
        ClientsSection,
        TestimonialsSection,
        ContactsSection,
        CareersSection,
      }}>
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
