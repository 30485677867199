import React from 'react'
import { withTranslation } from 'react-i18next'
import styles from './ProjectContent.module.scss'
import AppLinks from './AppLinks'
import Article from './Article'
import PicturesColumn from './PicturesColumn'
import { useWindowSize } from 'hooks/useWindowSize'
import { ImageHandler } from './ImageHandler'

const ContainerView = withTranslation()(({ t, project }) => {
  const [width] = useWindowSize()

  const hasLinks = !!project.googlePlayLink && !!project.appStoreLink

  let clientWrapperStyles = [styles.raw__client]
  let disciplineWrapperStyles = [styles.raw__disciplines]
  let processWrapperStyles = [styles.raw__process]
  let resultWrapperStyles = [styles.raw__result]
  let secondColumnStyles = [styles.column]

  if (project.id === 'cApprove') {
    clientWrapperStyles.push(styles.raw__client__cApp)
    disciplineWrapperStyles.push(styles.raw__disciplines__cApp)
    resultWrapperStyles.push(styles.raw__result__cApp)
  }
  if (project.id === 'cExpense') {
    secondColumnStyles.push(styles.column__cExp)
  }
  if (project.id === 'Nice') {
    clientWrapperStyles.push(styles.raw__client__Nice)
    disciplineWrapperStyles.push(styles.raw__disciplines__Nice)
    processWrapperStyles.push(styles.raw__process__Nice)
    resultWrapperStyles.push(styles.raw__result__Nice)
    resultWrapperStyles.push(styles.description__Nice)
    secondColumnStyles.push(styles.column__Nice)
  }

  return (
    <section className={styles.project}>
      <div className={styles.container}>
        <div className={width > 767 ? `col ${styles.firstColumn}` : ''}>
          <div className={styles.companyBox}>
            <h3 className={`${styles.name__title} ${project.id === 'cExpense' && styles.name__title__cExp}`}>
              {t(project?.name)}
            </h3>
            {width <= 766 && project.mobileImages.main && (
              <ImageHandler src={project.mobileImages.main} name={project.name} addStyles />
            )}
          </div>
          <Article
            t={t}
            title={t('projects.page.client_title')}
            wrapperStyles={clientWrapperStyles.join(' ')}
            textData={{ data: project?.description, style: styles.description__Nice }}
          />
          {width <= 766 && project.mobileImages.underClient && (
            <ImageHandler src={project.mobileImages.underClient} name={project.name} />
          )}
          <Article
            t={t}
            title={t('projects.page.disciplines_title')}
            project={project}
            blocks
            wrapperStyles={disciplineWrapperStyles.join(' ')}
          />
          {width <= 766 && project.mobileImages.underDisciplines && (
            <ImageHandler src={project.mobileImages.underDisciplines} name={project.name} />
          )}
          <Article
            t={t}
            title={t('projects.page.process_title')}
            wrapperStyles={processWrapperStyles.join(' ')}
            textData={{ data: project.processText }}
          />
          {width <= 766 && project.mobileImages.underProcess && (
            <ImageHandler src={project.mobileImages.underProcess} name={project.name} />
          )}
          {project.id !== 'Nice' && (
            <Article
              t={t}
              title={t('projects.page.result_title')}
              wrapperStyles={resultWrapperStyles.join(' ')}
              textData={{ data: project.resultsText }}
            />
          )}
          {width <= 766 && project.mobileImages.underResult && (
            <ImageHandler src={project.mobileImages.underResult} name={project.name} />
          )}
          {width <= 766 && hasLinks && <AppLinks project={project} t={t} />}
        </div>
        {/* SECOND COLUMN: ONLY FOR TABLETS AND DESKTOPS */}
        {width > 767 && (
          <div className={secondColumnStyles.join(' ')}>
            <PicturesColumn project={project} />
            {project.id === 'Nice' && (
              <Article
                t={t}
                title={t('projects.page.result_title')}
                wrapperStyles={resultWrapperStyles.join(' ')}
                textData={{ data: project.resultsText }}
                descriptionStyle={styles.descWidth}
              />
            )}
            {hasLinks && <AppLinks project={project} t={t} />}
          </div>
        )}
      </div>
    </section>
  )
})

export default ContainerView
