import React from 'react'
import { MainLayout } from 'components'
import { CareerGroup } from './CareerGroup'
import data from '../../assets/data/careers.json'
import { useGlobalContext } from 'context'
import { useWindowSize } from 'hooks/useWindowSize'

const CareersPage = () => {
  const [width] = useWindowSize()
  const [isOpenIndex, setOpenIndex] = React.useState(-1)
  const { CareersSection } = useGlobalContext()

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <MainLayout>
      <section className="careers" ref={CareersSection}>
        <a name="careers"></a>
        <div className="careers__container">
          <h1 className="title is-underline">Careers</h1>
          {width > 800 && <h2 className="subtitle">We are looking for...</h2>}
          <div className="accordion">
            {data.careers.map((line, index) => {
              return (
                <CareerGroup
                  key={line.title}
                  line={line}
                  index={index}
                  isOpenIndex={isOpenIndex}
                  setOpenIndex={setOpenIndex}
                />
              )
            })}
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

export default CareersPage
