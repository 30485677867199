import { useWindowSize } from 'hooks/useWindowSize'
import React from 'react'
import styles from './ProjectContent.module.scss'

export const ImageHandler = ({ src, name, addStyles }) => {
  const [width] = useWindowSize()
  const place = window.location.pathname

  let extraStyle = {}

  if (place === '/project/cExpense') {
    extraStyle = {
      height: `${width > 576 ? '340px' : '280px'}`,
      maxWidth: '55%',
      objectFit: 'contain',
      marginTop: '-30px',
      marginRight: `${width > 576 ? '0px' : '-36px'}`,
      width: 'auto',
    }
  }

  if (place === '/project/WellCall') {
    extraStyle = {
      height: `${width > 576 ? '420px' : '280px'}`,
      objectFit: 'contain',
      marginTop: `${width > 576 ? '-80px' : '-40px'}`,
      width: 'auto',
    }
  }

  if (place === '/project/cApprove') {
    extraStyle = {
      maxWidth: '70%',
      marginRight: '-36px',
      marginTop: '-50px',
      justifyContent: 'flex-end',
      height: `${width > 576 ? '300px' : '260px'}`,
      width: 'auto',
    }
  }

  if (place === '/project/Nice') {
    extraStyle = {
      width: `${width > 576 ? '80%' : '50%'}`,
      minWidth: `${width > 576 ? '220px' : '190px'}`,
      marginRight: '-75px',
      justifyContent: 'flex-end',
    }
  }

  return (
    <>
      {src.length > 1 ? (
        <div className={styles.imgMapBox}>
          {src.map(i => {
            return (
              <div key={`${name}-${src.slice(-7)}`} className={`${styles.pictureBox50}`}>
                <img src={i} alt={name} />
              </div>
            )
          })}
        </div>
      ) : (
        <div className={`${styles.pictureBox100}`} style={addStyles && extraStyle}>
          <img src={src[0]} alt={name} className={styles.img} />
        </div>
      )}
    </>
  )
}
