import { useGlobalContext } from '../../context.js'
import React from 'react'
import { withTranslation } from 'react-i18next'

const ContainerView = ({ t }) => {
  const { AboutSection } = useGlobalContext()
  return (
    <section className="about is-dark-bckg" ref={AboutSection}>
      <h2 className="title is-underline">{t('about_section.title')}</h2>
      <div className="about__content">
        <p className="about__content-item">{t('about_section.description')}</p>
        <p className="about__content-item">{t('about_section.description2')}</p>
      </div>
    </section>
  )
}

export default withTranslation()(ContainerView)
