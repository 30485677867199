import { useGlobalContext } from 'context'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Slider from 'react-slick'
import data from '../../assets/data/testimonials.json'
import { FeedbackItem } from './FeedbackItem'

const ContainerView = ({ settings }) => {
  const { TestimonialsSection } = useGlobalContext()
  return (
    <section className="feedback" ref={TestimonialsSection}>
      <h1 className={'title is-underline'}>How it is to work with us</h1>
      <div className="feedback__wrap">
        <Slider {...settings} className="feedback__wrap__slider">
          {data.testimonials.map(person => {
            return <FeedbackItem key={person.name} person={person} na />
          })}
        </Slider>
      </div>
    </section>
  )
}

export default withTranslation()(ContainerView)
