import LegoItem from 'components/LegoItem'
import React from 'react'
import styles from './ProjectContent.module.scss'

const MapText = ({ t, data, style }) => {
  return (
    <>
      {data.length > 1 ? (
        data.map((desc, index) => {
          return (
            <p key={desc} className={`${styles.description} ${style ? style : ''} ${index > 0 ? styles.part : ''}`}>
              {t(desc)}
            </p>
          )
        })
      ) : (
        <div className={`${styles.description}`}>{t(data[0])}</div>
      )}
    </>
  )
}

function Article({ t, title, wrapperStyles, textData, blocks = false, project = {}, descriptionStyle }) {
  return (
    <div className={wrapperStyles}>
      <h3 className={styles.title}>{title}</h3>
      {blocks ? (
        <div className={styles.block}>
          {project.legoBlocks.map(item => {
            return <LegoItem title={item.title} key={item.title} img={item.img} />
          })}
        </div>
      ) : (
        <MapText {...textData} t={t} style={descriptionStyle} />
      )}
    </div>
  )
}

export default Article
