import React from 'react'
import styles from './ProjectContent.module.scss'

function PicturesColumn({ project }) {
  return project.images.map((img, index) => {
    return <div className={styles[project.images[index]]} key={img} />
  })
}

export default PicturesColumn
