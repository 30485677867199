import { RedLine } from 'assets/svgs/red-line'
import { useWindowSize } from 'hooks/useWindowSize'
import React from 'react'

export const Career = ({ position }) => {
  const [width] = useWindowSize()
  return (
    <div className="position forClick">
      <div className="position__container forClick">
        {width < 577 && <RedLine />}
        <div className="flex">
          <p className="position__title forClick">{position.title}</p>
          <a href="mailto:boris@letme-agency.com?subject=CV for Job Request" className="position__button forClick">
            <span>Send CV</span>
          </a>
        </div>
      </div>
    </div>
  )
}
