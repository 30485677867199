import { HeaderLogo } from 'assets/svgs/logo-header'
import { MobileLogo } from 'assets/svgs/logo-mobile'

import { useWindowScrollY } from 'hooks/useWindowScrollY'
import { useWindowSize } from 'hooks/useWindowSize'

import data from '../../assets/data/header.json'
import React, { useState } from 'react'
import { BurgerMenu } from 'components/BurgerMenu/BurgerMenu'

const ContainerView = () => {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false)
  const [scrollY] = useWindowScrollY()
  const [width] = useWindowSize()

  return (
    <>
      {width < 750 && <BurgerMenu isOpen={isBurgerMenuOpen} setOpen={setBurgerMenuOpen} />}
      <div className="header">
        <div className="container">
          <div
            className={`header__svg-box 
            ${scrollY > 320 && 'header__appear'} 
            `}>
            {width > 900 && (
              <a href={window.location.pathname === '/' ? '#top' : '/'}>
                <HeaderLogo />
              </a>
            )}
            {width <= 900 && width > 750 && (
              <a href={window.location.pathname === '/' ? '#top' : '/'}>
                <MobileLogo />
              </a>
            )}
          </div>

          <div className="header__nav">
            {width > 750 ? (
              data.navBar.map(i => {
                return (
                  <a href={i.link} key={i.title} className="header__nav-item">
                    <div>{i.title}</div>
                  </a>
                )
              })
            ) : (
              <button
                className={`hamburger hamburger--slider ${isBurgerMenuOpen ? 'is-active' : ''}`}
                type="button"
                onClick={() => setBurgerMenuOpen(!isBurgerMenuOpen)}>
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ContainerView
