import React from 'react'
import CareersPage from './CareersPage'

const ContainerView = () => (
  <div>
    <CareersPage />
  </div>
)

export default ContainerView
