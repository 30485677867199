import React from 'react'

export const MountainsSVG = () => (
  <svg width="100%" height="auto" viewBox="0 0 375 285" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69 121.767L187.302 41.7829L208.811 57.3023L298.434 0L375 47V131V154V267H69V154V131V121.767Z"
      fill="#061F3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 129L77.4063 78L210.236 162.253V213.859H209V285H0V213.859V194V129Z"
      fill="#061F3F"
    />
    <path
      d="M288 31L298.5 0L375 47V108L244 154L252.376 138.481L264.341 118.186L252.376 58.4961L303.827 44.1705L288 31Z"
      fill="url(#paint0_linear_1601_178)"
    />
    <path
      d="M67.8986 105.65L77.1812 78.2736L375 214.274V285H1L36.4046 200.568L46.9827 182.646L36.4046 129.932L81.8902 117.281L67.8986 105.65Z"
      fill="url(#paint1_linear_1601_178)"
    />
    <path d="M209 57.5L187.5 41.5L173 79L209 57.5Z" fill="#1F3D64" />
    <defs>
      <linearGradient
        id="paint0_linear_1601_178"
        x1="357.671"
        y1="-8.35659"
        x2="357.671"
        y2="162.357"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#294A74" />
        <stop offset="0.441422" stopColor="#102B4E" />
        <stop offset="0.874898" stopColor="#061F3F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1601_178"
        x1="129.491"
        y1="70.8937"
        x2="129.491"
        y2="221.653"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#294A74" />
        <stop offset="0.441422" stopColor="#102B4E" />
        <stop offset="0.874898" stopColor="#061F3F" />
      </linearGradient>
    </defs>
  </svg>
)
