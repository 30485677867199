import { useWindowSize } from 'hooks/useWindowSize'
import React, { useEffect, useState } from 'react'
import techData from '../../assets/data/technology.json'
import { SingleTech } from './SingleTech'

export const TabsContainer = ({ openTabIndex, setOpenTabIndex }) => {
  const [width] = useWindowSize()
  const [topButtons, setTopButtons] = useState(techData.technology)

  useEffect(() => {
    if (width < 870) {
      setTopButtons(techData.technology.slice(0, 2))
    } else {
      setTopButtons(techData.technology)
    }
  }, [width])
  return (
    <section className="techtabs">
      <div className="techtabs__layout">
        <div className="techtabs__container">
          <div className="techtabs__buttons-box techtabs__button-box-up">
            {topButtons.map((item, index) => {
              return (
                <button
                  className={`techtabs__button techtabs__button-up pos-${Math.abs(openTabIndex - index)}`}
                  key={item.categoryTitle}
                  onClick={() => setOpenTabIndex(index)}>
                  {item.categoryTitle}
                </button>
              )
            })}
          </div>
          <div className="techtabs__buttons-box techtabs__button-box-down">
            {width < 870 &&
              techData.technology.slice(-2).map((item, index) => {
                return (
                  <button
                    className={`techtabs__button techtabs__button-bottom pos-${Math.abs(index - openTabIndex + 2)}`}
                    key={item.categoryTitle}
                    onClick={() => setOpenTabIndex(index + 2)}>
                    {item.categoryTitle}
                  </button>
                )
              })}
          </div>

          <div className="techtabs__tech-box">
            <div className={`techtabs__tech-row fr-padding-top`}>
              {width > 992 && techData.technology[openTabIndex].data.first.groupName && (
                <div className="techtabs__group-name">{techData.technology[openTabIndex].data.first.groupName}</div>
              )}
              <div className={`flex spTop-${openTabIndex}`}>
                {techData.technology[openTabIndex].data.first.tech.map(t => {
                  return <SingleTech key={t.title} title={t.title} img={t.img} index={openTabIndex} />
                })}
              </div>
            </div>
            <div className={`techtabs__tech-row  fb-padding-top `}>
              {width > 992 && techData.technology[openTabIndex].data.second.groupName && (
                <div className="techtabs__group-name">{techData.technology[openTabIndex].data.second.groupName}</div>
              )}
              <div className={`flex spBottom-${openTabIndex}`}>
                {techData.technology[openTabIndex].data.second.tech.map(t => {
                  return <SingleTech key={t.title} title={t.title} img={t.img} index={openTabIndex} />
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
