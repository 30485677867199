import i18next from 'i18next'
import Translate from './utils/Translate'

//xml to json versus versa: http://www.utilities-online.info/xmltojson/#.Xy-noygzaUl
i18next
  .use(Translate.cache)
  .use(Translate.initReactI18next)
  .use(Translate.languageDetector)
  .use(Translate.postProcessor)
  .init(
    {
      initImmediate: false,
      resources: Translate.languages,
      fallbackLng: Translate.fallbackLng,
      load: Translate.load,
      lng: Translate.language,
      debug: false,
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
      react: {
        wait: true,
        useSuspense: false,
      },
    },
    err => {
      if (err) return console.error(err)
    }
  )

// if (Translate.rtl.includes(i18next.language)) Translate.setDirection('rtl')
// else Translate.setDirection('ltr')
export default i18next
