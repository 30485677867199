import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
// import { TechnologyItem } from 'components'
import { TabsContainer } from './TabsContainer'
const ContainerView = () => {
  const [openTabIndex, setOpenTabIndex] = useState(0)

  return (
    <section className="tech">
      <div className="tech__wrapper"></div>
      <h1 className="title is-underline">The technology we use</h1>
      <TabsContainer openTabIndex={openTabIndex} setOpenTabIndex={setOpenTabIndex} />
      <a name="About"></a>
    </section>
  )
}

export default withTranslation()(ContainerView)
