import { db_backend_dark, qa_dark, ui_dark, ux_dark } from 'assets/imgs/lego'
import { cApprove, cExpense, Nice, WellCall, cApproveM, NiceM, cExpenseM, WellCallM } from '../imgs/projects'

const mainScreenProjects = [
  {
    id: 'cExpense',
    pathImg: cExpense,
    pathImgM: cExpenseM,
    title: 'projects.card_title_1',
    desc: 'projects.card_descr_1',
  },
  {
    id: 'WellCall',
    pathImg: WellCall,
    pathImgM: WellCallM,
    title: 'projects.card_title_2',
    desc: 'projects.card_descr_2',
  },
  {
    id: 'cApprove',
    pathImg: cApprove,
    pathImgM: cApproveM,
    title: 'projects.card_title_3',
    desc: 'projects.card_descr_3',
  },
  {
    id: 'Nice',
    pathImg: Nice,
    pathImgM: NiceM,
    title: 'projects.card_title_4',
    desc: 'projects.card_descr_4',
  },
]

const projectsData = [
  {
    id: 'cExpense',
    name: 'projects.page.client_name_1',
    description: ['projects.page.client_desc_1'],
    processText: [
      'projects.page.process_text_1.part_1',
      'projects.page.process_text_1.part_2',
      'projects.page.process_text_1.part_3',
    ],
    resultsText: [
      'projects.page.result_text_1.part_1',
      'projects.page.result_text_1.part_2',
      'projects.page.result_text_1.part_3',
    ],
    legoBlocks: [
      {
        title: 'projects.page.disciplines_name_1',
        img: ux_dark,
      },
      {
        title: 'projects.page.disciplines_name_3',
        img: db_backend_dark,
      },
      {
        title: 'projects.page.disciplines_name_2',
        img: ui_dark,
      },
      {
        title: 'projects.page.disciplines_name_4',
        img: qa_dark,
      },
    ],
    images: ['image1', 'image2', 'image3', 'image4'],
    mobileImages: {
      main: ['/images/ProjectsMobile/cExp1.png'],
      underClient: ['/images/ProjectsMobile/cExp2.png'],
      underDisciplines: '',
      underProcess: ['/images/ProjectsMobile/cExp3.png', '/images/ProjectsMobile/cExp4.png'],
      underResult: '',
    },
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.combtas.mobile',
    appStoreLink: 'https://apps.apple.com/il/app/combtas-cexpense/id1455842812',
  },
  {
    id: 'WellCall',
    name: 'projects.page.client_name_2',
    description: ['projects.page.client_desc_2'],
    processText: [
      'projects.page.process_text_2.part_1',
      'projects.page.process_text_2.part_2',
      'projects.page.process_text_2.part_3',
    ],
    resultsText: ['projects.page.result_text_2.part_1', 'projects.page.result_text_2.part_2'],
    legoBlocks: [
      {
        title: 'projects.page.disciplines_name_1',
        img: ux_dark,
      },
      {
        title: 'projects.page.disciplines_name_3',
        img: db_backend_dark,
      },
      {
        title: 'projects.page.disciplines_name_2',
        img: ui_dark,
      },
    ],
    images: ['image5', 'image6', 'image7'],
    mobileImages: {
      main: ['/images/ProjectsMobile/WellCall1.png'],
      underClient: '',
      underDisciplines: '',
      underProcess: '',
      underResult: ['/images/ProjectsMobile/WellCall2.png', '/images/ProjectsMobile/WellCall3.png'],
    },
    googlePlayLink: 'https://play.google.com/store/apps/details?id=com.wellcall',
    appStoreLink: 'https://apps.apple.com/il/app/wellcall/id1532379904',
  },
  {
    id: 'cApprove',
    name: 'projects.page.client_name_3',
    description: ['projects.page.client_desc_3'],
    processText: ['projects.page.process_text_3.part_1', 'projects.page.process_text_3.part_2'],
    resultsText: ['projects.page.result_text_3.part_1', 'projects.page.result_text_3.part_2'],
    legoBlocks: [
      {
        title: 'projects.page.disciplines_name_1',
        img: ux_dark,
      },
      {
        title: 'projects.page.disciplines_name_3',
        img: db_backend_dark,
      },
      {
        title: 'projects.page.disciplines_name_2',
        img: ui_dark,
      },
    ],
    images: ['image8', 'image9', 'image10', 'image11'],
    mobileImages: {
      main: ['/images/ProjectsMobile/cApp1.png'],
      underClient: '',
      underDisciplines: ['/images/ProjectsMobile/cApp2.png'],
      underProcess: '',
      underResult: ['/images/ProjectsMobile/cApp3.png', '/images/ProjectsMobile/cApp4.png'],
    },
    googlePlayLink: 'https://play.google.com/store/apps/details?id=combtas.capprove.mobile',
    appStoreLink: 'https://apps.apple.com/ua/app/combtas-capprove/id1564675279',
  },
  {
    id: 'Nice',
    name: 'projects.page.client_name_4',
    description: ['projects.page.client_desc_4.part_1', 'projects.page.client_desc_4.part_2'],
    processText: [
      'projects.page.process_text_4.part_1',
      'projects.page.process_text_4.part_2',
      'projects.page.process_text_4.part_3',
    ],
    resultsText: [
      'projects.page.result_text_4.part_1',
      'projects.page.result_text_4.part_2',
      'projects.page.result_text_4.part_3',
    ],
    legoBlocks: [
      {
        title: 'projects.page.disciplines_name_1',
        img: ux_dark,
      },
      {
        title: 'projects.page.disciplines_name_3',
        img: db_backend_dark,
      },
      {
        title: 'projects.page.disciplines_name_2',
        img: ui_dark,
      },
      {
        title: 'projects.page.disciplines_name_4',
        img: qa_dark,
      },
    ],
    images: ['image12', 'image13'],
    mobileImages: {
      main: ['/images/ProjectsMobile/Nice1.png'],
      underClient: '',
      underDisciplines: '',
      underProcess: '',
      underResult: ['/images/ProjectsMobile/Nice2.png'],
    },
    googlePlayLink: '',
    appStoreLink: '',
  },
]

function getProjectById(id) {
  return projectsData.find(project => project.id === id)
}

export { mainScreenProjects, projectsData, getProjectById }
