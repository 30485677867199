import React from 'react'
export const Stars = () => {
  return (
    <div className="stars">
      <div className="stars__star stars__star__small stars__star__s1"></div>
      <div className="stars__star stars__star__small stars__star__s2"></div>
      <div className="stars__star stars__star__small stars__star__s3"></div>

      <div className="stars__star stars__star__medium stars__star__s4"></div>
      <div className="stars__star stars__star__medium stars__star__s5"></div>
      <div className="stars__star stars__star__medium stars__star__s6"></div>
      <div className="stars__star stars__star__medium stars__star__s7"></div>

      <div className="stars__star stars__star__big stars__star__s8"></div>
      <div className="stars__star stars__star__big stars__star__s9"></div>
    </div>
  )
}
