import React from 'react'
import { withTranslation } from 'react-i18next'
import styles from './LegoItem.module.scss'

const ContainerView = ({ t, img, title }) => {
  return (
    <div className={styles.container}>
      <img src={img} alt={title} />
      <div>{t(title)}</div>
    </div>
  )
}

export default withTranslation()(ContainerView)
