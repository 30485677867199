import React, { useEffect } from 'react'
import data from '../../assets/data/header.json'
export const BurgerMenu = ({ isOpen, setOpen }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <div className={`burger ${isOpen ? 'burger__appear' : 'burger__disappear'}`}>
      <div className="burger__container">
        <div className="burger__nav-items">
          {data.navBar.map(i => {
            return (
              <a href={i.link} key={i.title} onClick={() => setOpen(false)}>
                <div className="burger__nav-item">{i.title}</div>
              </a>
            )
          })}
        </div>
        <div className="burger__footer">
          <div className="footer__nav-item icon-Facebook---Negative"></div>
          <div className="footer__nav-item icon-Instagram---Negative"></div>
          <div className="footer__nav-item icon-LinkedIn---Negative"></div>
        </div>
      </div>
    </div>
  )
}
