import React from 'react'
import ContainerView from './view'
import { ArrowButton } from '../ArrowButton'
import { useWindowSize } from 'hooks/useWindowSize'

const TeamSection = () => {
  const [width] = useWindowSize()
  const settings = {
    slidesToShow: (() => (width > 850 ? 3 : 2))(),
    slidesToScroll: (() => (width > 850 ? 3 : 2))(),
    speed: 500,
    rows: 2,
    arrows: (() => width > 1000)(),
    slidesPerRow: 1,
    infinite: false,
    touchThreshold: 20,
    prevArrow: <ArrowButton />,
    nextArrow: <ArrowButton />,
  }

  return <ContainerView settings={settings} />
}

export default TeamSection
