import React from 'react'

const ContainerView = ({ name, position, img }) => (
  <div className="team__item">
    <img className="team__img" src={img} alt={name} />
    <span className="team__name is-underline">{name}</span>
    <span className="team__position">{position}</span>
  </div>
)

export default ContainerView
