import React from 'react'
import { AppStoreLogo } from 'assets/svgs/appstore'
import { GooglePlayLogo } from 'assets/svgs/googleplay'
import styles from './ProjectContent.module.scss'
import { useWindowSize } from 'hooks/useWindowSize'

function AppLinks({ project, t }) {
  const [width] = useWindowSize()
  return (
    <>
      <h3
        className={`
          ${styles.download__title}  
          ${project.id === 'WellCall' && styles.download__title__WellCall}
          ${project.id === 'cApprove' && styles.download__title__cApp}
          ${project.id === 'Nice' && styles.download__title__Nice}
        `}>
        {t('projects.page.download_title')}
      </h3>
      <div
        className={`
            ${styles.download__block} 
            ${project.id === 'WellCall' && styles.download__block__WellCall}
            ${project.id === 'cApprove' && styles.download__block__cApp}
            ${project.id === 'Nice' && styles.download__block__Nice}
            `}>
        <div className={styles.svg}>
          <a href={project?.appStoreLink}>{width > 767 ? <AppStoreLogo /> : <AppStoreLogo width={33} height={33} />}</a>
        </div>
        <div className={styles.svg}>
          <a href={project?.googlePlayLink}>
            {width > 767 ? <GooglePlayLogo /> : <GooglePlayLogo width={32} height={36} />}
          </a>
        </div>
      </div>
    </>
  )
}

export default AppLinks
