import { useWindowSize } from 'hooks/useWindowSize'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as LightBulb } from '../../assets/svgs/light_bulb.svg'
import { ReactComponent as LightBulbSmall } from '../../assets/svgs/light_bulb_small.svg'
import { Stars } from './Stars'
import { MountainsSVG } from 'assets/svgs/MTNS'

export const Page404 = () => {
  const navigate = useNavigate()
  const [width] = useWindowSize()

  return (
    <>
      <Stars />
      <div className="not-found">
        <div className="not-found__404">
          <div className="not-found__404__0">
            <div className="not-found__svg">{width > 576 ? <LightBulb /> : <LightBulbSmall />}</div>
            <div className="not-found__404__4 not-found__404__4__L">4</div>
            <div className="not-found__404__4 not-found__404__4__R">4</div>
          </div>
        </div>
        <div className="not-found__oops">Oops!</div>
        <div className="not-found__button" onClick={() => navigate('/')}>
          Go to Main Page
        </div>
        {width <= 600 && (
          <div className="not-found__mtns">
            <MountainsSVG />
          </div>
        )}
      </div>
    </>
  )
}
