import {
  alexey,
  alex,
  anna,
  anton,
  boris,
  evgenii,
  igor,
  ilia,
  larisa,
  leonid,
  liza,
  oleksandr,
  rufina,
  vladislav,
  khatuna,
} from '../imgs/team'

const teamData = [
  {
    id: 0,
    name: 'Boris Shapiro',
    position: 'Founder, CEO',
    avatar: boris,
  },
  {
    id: 3,
    name: 'Alexey Pochuev',
    position: 'Senior Fullstack Dev.',
    avatar: alexey,
  },
  {
    id: 1,
    name: 'Larisa Grushchenko',
    position: 'Frontend Lead',
    avatar: larisa,
  },
  {
    id: 4,
    name: 'Rufina Berinskaya',
    position: 'Senior Fullstack Dev.',
    avatar: rufina,
  },
  {
    id: 2,
    name: 'Lizaveta Malevich',
    position: 'Project manager',
    avatar: liza,
  },
  {
    id: 5,
    name: 'Vlad Khayt',
    position: 'QA specialist',
    avatar: vladislav,
  },
  {
    id: 6,
    name: 'Anna Tsimerman',
    position: 'UX/UI Designer',
    avatar: anna,
  },
  {
    id: 7,
    name: 'Leonid Golender',
    position: 'Solutions Architect, DB and Backend Dev.',
    avatar: leonid,
  },
  {
    id: 9,
    name: 'Igor Kojanov',
    position: 'Senior .NET Developer',
    avatar: igor,
  },
  {
    id: 10,
    name: 'Evgenii Bobylev',
    position: 'Senior .NET Developer',
    avatar: evgenii,
  },
  {
    id: 8,
    name: 'Anton Umnitsyn',
    position: 'Senior iOS Developer',
    avatar: anton,
  },
  {
    id: 13,
    name: 'Ilia Korobov',
    position: 'Frontend Developer',
    avatar: ilia,
  },
  {
    id: 12,
    name: 'Aleksandr Vorona',
    position: 'Frontend Developer',
    avatar: alex,
  },
  {
    id: 11,
    name: 'Oleksandr Morozov',
    position: 'Frontend Developer',
    avatar: oleksandr,
  },
  {
    id: 14,
    name: 'Khatuna Shaveshova',
    position: 'Frontend Developer',
    avatar: khatuna,
  },
]

export default teamData
