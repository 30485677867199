import en from '../locales/en/translation.json'
import he from '../locales/he/translation.json'
import LanguageDetector from 'i18next-browser-languagedetector'
import postProcessor from 'i18next-sprintf-postprocessor'
import { initReactI18next } from 'react-i18next'
import Cache from 'i18next-localstorage-cache'
import i18next from 'i18next'

class Translate {
  //Language Config
  language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
  fallbackLng = localStorage.getItem('language') ? localStorage.getItem('language') : 'en'
  load = 'all'
  languages = {
    en,
    he,
  }
  cache = Cache
  languageDetector = LanguageDetector
  postProcessor = postProcessor
  initReactI18next = initReactI18next
  country_language = { US: 'en', IL: 'he' }
  rtl = ['he']

  getLanguage(country) {
    if (this.country_language[country]) return this.country_language[country]
    else return 'en'
  }

  getCurrentCountry(language) {
    if (!language) language = localStorage.getItem('language')
    let country = Object.keys(this.country_language).find(key => this.country_language[key] === language)
    if (country !== undefined) return country
    else return 'IL'
  }

  async setLanguage(lang) {
    localStorage.setItem('language', lang)
    return lang
  }

  isRtl() {
    return localStorage.getItem('language') === 'he'
  }

  getCountries() {
    return Object.keys(this.country_language)
  }

  getLanguages() {
    return Object.values(this.country_language)
  }

  setDirection(dir) {
    if (dir) {
      document.getElementsByTagName('html')[0].setAttribute('dir', dir)
    } else {
      let currentLanguage = this.getCurrentCountry()
      if (this.rtl.includes(currentLanguage.toLowerCase()))
        document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl')
      else document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr')
    }
  }

  setExternal(language) {
    i18next.changeLanguage(language)
    let languages = this.getLanguages()
    if (languages.includes(language.toLowerCase())) {
      if (this.ltl.includes(language.toLowerCase())) document.getElementsByTagName('html')[0].setAttribute('dir', 'ltl')
      else document.getElementsByTagName('html')[0].setAttribute('dir', 'rtr')
      localStorage.setItem('language', language)
      return this.getCurrentCountry(language.toLowerCase())
    } else {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtr')
      localStorage.setItem('language', 'he')
      return 'IL'
    }
  }
}

export default new Translate()
