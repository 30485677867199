import React from 'react'

const ContainerView = ({ title, descr }) => (
  <div className="card">
    <h3 className="card__name is-underline">{title}</h3>
    <p className="card__desc">{descr}</p>
  </div>
)

export default ContainerView
