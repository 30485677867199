import React from 'react'

export const HeaderLogo = () => (
  <svg width="165" height="165" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M142.227 4.88758e-05L0 22.5266L22.5266 164.754L164.754 142.227L142.227 4.88758e-05Z" fill="#F0505C" />
    <path d="M26.5864 38.7916L43.2956 36.1451L51.4635 87.7148L34.7543 90.3613L26.5864 38.7916Z" fill="white" />
    <path
      d="M93.7873 62.0479L94.3387 65.529L72.0597 69.0576C72.0597 69.0576 73.3624 72.7765 78.9322 71.8944C81.2427 71.3491 83.2909 70.0151 84.7239 68.1223L92.1304 76.584C88.7408 80.8877 83.8178 83.7134 78.392 84.4694C66.5563 86.344 56.4752 79.0196 54.766 68.2283C53.9472 63.0581 55.2157 57.7744 58.2925 53.5395C61.3693 49.3047 66.0024 46.4655 71.1725 45.6466C76.3427 44.8278 81.6264 46.0963 85.8612 49.1731C90.0961 52.2499 92.9353 56.883 93.7541 62.0531L93.7873 62.0479ZM76.8934 61.597C76.4523 58.8122 74.8393 57.6403 73.0988 57.9159C71.3583 58.1916 70.1864 59.8046 70.6275 62.5895L76.8934 61.597Z"
      fill="white"
    />
    <path
      d="M96.5517 56.9711L94.3463 43.0468L99.2198 42.2749L97.3452 30.4392L114.021 27.798L115.896 39.6337L122.162 38.6413L124.367 52.5656L118.101 53.558L119.039 59.4759C119.48 62.2607 121.093 63.4326 123.181 63.1018C124.128 62.9422 125.055 62.6814 125.947 62.324L128.152 76.2483C126.168 77.0353 124.104 77.6016 121.996 77.9369C111.553 79.591 104.295 74.3175 102.365 62.1337L101.425 56.1992L96.5517 56.9711Z"
      fill="white"
    />
    <path
      d="M95.9452 111.613L99.0406 131.157L82.3314 133.803L79.236 114.259C78.7949 111.474 77.1819 110.303 75.7729 110.526C74.3639 110.749 73.2086 112.359 73.6496 115.144L76.7451 134.688L60.0027 137.34L56.9073 117.796C56.4662 115.011 54.8532 113.839 53.4608 114.06C52.0684 114.28 50.8965 115.893 51.3376 118.678L54.433 138.222L37.757 140.863L31.9048 103.914L47.9177 101.378L49.9718 105.334C50.7423 103.515 51.9787 101.931 53.5562 100.741C55.1338 99.5517 56.997 98.7988 58.9581 98.5584C65.9203 97.4557 70.4516 103.535 70.4516 103.535C70.4516 103.535 73.5787 96.2428 80.5574 95.1374C87.5362 94.0321 93.9052 98.7328 95.9452 111.613Z"
      fill="white"
    />
    <path
      d="M139.956 105.713L140.507 109.194L118.228 112.723C118.228 112.723 119.531 116.441 125.101 115.559C127.424 115.02 129.485 113.683 130.926 111.782L138.332 120.244C134.943 124.547 130.019 127.373 124.594 128.129C112.758 130.004 102.677 122.679 100.968 111.888C100.149 106.718 101.417 101.434 104.494 97.1992C107.571 92.9643 112.204 90.1252 117.374 89.3063C122.544 88.4874 127.828 89.7559 132.063 92.8327C136.298 95.9096 139.137 100.543 139.956 105.713ZM123.112 105.254C122.671 102.469 121.058 101.297 119.317 101.573C117.577 101.849 116.405 103.462 116.846 106.246L123.112 105.254Z"
      fill="white"
    />
  </svg>
)
