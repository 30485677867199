import React from 'react'
import { Header, Footer } from 'components'

const ContainerView = ({ children }) => (
  <main className="layout">
    <Header></Header>
    <div className="content">{children}</div>
    <Footer></Footer>
  </main>
)

export default ContainerView
