import React from 'react'
import { ChevronRight } from 'assets/svgs/chevron-right'
import { Career } from './Career'
import { ChevronRightSmall } from 'assets/svgs/chevron-right-small'
import { useWindowSize } from 'hooks/useWindowSize'

export const CareerGroup = ({ line, index, isOpenIndex, setOpenIndex }) => {
  const [width] = useWindowSize()
  const setIndex = index => {
    if (isOpenIndex === index) {
      setOpenIndex(-1)
    } else {
      setOpenIndex(index)
    }
  }

  //HANDLE CLICK OUTSIDE
  React.useEffect(() => {
    const handleClickOutside = event => {
      if (!event.target.className.includes('forClick')) {
        setOpenIndex(-1)
      }
    }
    document.addEventListener('click', handleClickOutside, false)
    return () => document.removeEventListener('click', handleClickOutside, false)
  }, [])

  return (
    <div className="careers-group">
      <div className={index === isOpenIndex ? 'careers-group__row extended' : 'careers-group__row'}>
        <div
          className={`careers-group__container forClick ${index === isOpenIndex && 'careers-group__container-tall'}`}
          onClick={() => setIndex(index)}>
          <div className={index === isOpenIndex ? 'rotate90 svg-box' : 'svg-box'}>
            {width > 577 ? <ChevronRight /> : <ChevronRightSmall />}
          </div>
          <p className={`careers-group__title forClick ${index === isOpenIndex && 'careers-group__title-selected'}`}>
            {line.title}
          </p>
        </div>

        <div className={index === isOpenIndex ? 'flex' : 'hidden'}>
          {line.positions.map(position => {
            return <Career key={position.title} position={position} />
          })}
        </div>
      </div>
    </div>
  )
}
