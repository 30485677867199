import React from 'react'
import { withTranslation } from 'react-i18next'
import { useGlobalContext } from 'context'
import data from '../../assets/data/partners.json'

const ContainerView = ({ t }) => {
  const { ClientsSection } = useGlobalContext()
  return (
    <section ref={ClientsSection} className="partners">
      <h2 className="title is-underline">{t('clients.our_clients')}</h2>
      <div className="partners__container">
        {data.partners.map(i => {
          return (
            <div key={i.title} className="partners__img-box">
              <img src={i.img} alt={i.title} />
            </div>
          )
        })}
      </div>
      <a name="Testimonials"></a>
    </section>
  )
}

export default withTranslation()(ContainerView)
