import React from 'react'
import {
  map,
  boris,
  larisa,
  liza,
  alexey,
  rufina,
  vladislav,
  leonid,
  anton,
  igor,
  oleksandr,
  anna,
  khatuna,
  alex,
  evgenii,
  ilia,
} from 'assets/imgs/team'

export const DynamicMap = ({ t }) => {
  return (
    <div>
      <h2 className="title is-underline">{t('team.around_globe')}</h2>
      <div className="team__wrap__map">
        <img className="team__map" src={map} alt="World map" />

        <div className="team__map__point tag-1" />
        <div className="team__map__group group-1">
          <div className="team__map__pointer pointer-1" />
          <img className="team__map__img employee-11" src={leonid} alt="Leonid Golender" />
          <img className="team__map__img employee-12" src={vladislav} alt="Vladislav Khayt" />
          <img className="team__map__img employee-13" src={alex} alt="Aleksandr Vorona" />
        </div>

        <div className="team__map__point tag-2" />
        <div className="team__map__group group-2">
          <div className="team__map__pointer pointer-2" />
          <img className="team__map__img employee-21" src={larisa} alt="Larisa Grushchenko" />
          <img className="team__map__img employee-22" src={rufina} alt="Rufina Berinskaya" />
          <img className="team__map__img employee-23" src={anton} alt="Anton Umnitsyn" />
          <img className="team__map__img employee-24" src={oleksandr} alt="Oleksandr Morozov" />
          <img className="team__map__img employee-25" src={ilia} alt="Ilia Korobov" />
          <img className="team__map__img employee-26" src={khatuna} alt="Khatuna Shaveshova" />
        </div>

        <div className="team__map__point tag-3" />
        <div className="team__map__group group-3">
          <div className="team__map__pointer pointer-3" />
          <img className="team__map__img employee-31" src={boris} alt="Boris Shapiro" />
          <img className="team__map__img employee-32" src={liza} alt="Lizaveta Malevich" />
          <img className="team__map__img employee-33" src={alexey} alt="Alexey Pochuev" />
          <img className="team__map__img employee-34" src={anna} alt="Anna Tsimerman" />
          <img className="team__map__img employee-35" src={igor} alt="Igor Kojanov" />
          <img className="team__map__img employee-36" src={evgenii} alt="Evgenii Bobylev" />
        </div>
        <a name="Projects" />
      </div>
    </div>
  )
}
