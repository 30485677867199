import React from 'react'
import { withTranslation } from 'react-i18next'
import { TeamItem } from 'components'
import Slider from 'react-slick'

import teamData from '../../assets/data/team'
import { useWindowSize } from 'hooks/useWindowSize'
import { DynamicMap } from './dynamic-map'

const ContainerView = ({ t, settings }) => {
  const [width] = useWindowSize()

  return (
    <section className="team">
      <div className="container">
        {width > 600 ? (
          <div className="team__wrap">
            <Slider {...settings} className="team__wrap__slider">
              {teamData.map(({ id, name, position, avatar }) => (
                <TeamItem key={id} name={name} position={position} img={avatar} na />
              ))}
            </Slider>
          </div>
        ) : (
          <div className="team__columns">
            {teamData.map(({ id, name, position, avatar }) => (
              <TeamItem key={id} name={name} position={position} img={avatar} na />
            ))}
          </div>
        )}
        {width > 1200 && <DynamicMap t={t} />}
      </div>
      {}
      {width <= 1200 && (
        <>
          <div className="title image-title">Work around the globe</div>
          <img className="team__image" src="/images/map.png" />
        </>
      )}
    </section>
  )
}

export default withTranslation()(ContainerView)
