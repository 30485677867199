import React from 'react'

export const ChevronRight = () => (
  <svg width="18" height="28" viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="3.97107"
      y1="3.2912"
      x2="15.0397"
      y2="14.3598"
      stroke="#061F3F"
      strokeWidth="4.82735"
      strokeLinecap="round"
    />
    <line
      x1="15.0398"
      y1="14.3477"
      x2="3.97119"
      y2="25.4163"
      stroke="#061F3F"
      strokeWidth="4.82735"
      strokeLinecap="round"
    />
  </svg>
)
