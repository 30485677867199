import React from 'react'

export const AppStoreLogo = ({ width = '62', height = '62', fillColor = '#061F3F' }) => (
  <svg width={width} height={height} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31 62C13.8792 62 0 48.1208 0 31C0 13.8792 13.8792 0 31 0C48.1208 0 62 13.8792 62 31C61.9812 48.113 48.113 61.9812 31 62ZM18 40C17.6001 40.0023 17.3891 39.9076 17 40H16L14 43C13.3015 44.1652 13.8719 45.243 15 46C16.1281 46.757 17.1865 47.088 18 46H19L21 41C20.1954 39.9239 19.3436 40.0097 18 40ZM35 21C34.0355 21.906 33.2157 22.6944 33 24C32.6544 25.8003 33.0555 27.4289 34 29L43 46C43.4603 46.7784 44.0957 46.998 45 47C45.3879 46.9988 46.6514 47.1701 47 47C48.1951 46.4106 48.4953 44.2371 48 43L45 39H49C50.3404 38.9888 50.9184 37.338 51 36C51.0816 34.662 50.3291 34.1741 49 34H42L35 22V21ZM13 34C12.6715 34.0004 12.304 33.8755 12 34C11.6919 34.1286 11.2364 33.7642 11 34C10.5224 34.4788 10.9988 35.3237 11 36C11.0004 37.2846 11.7259 38.8363 13 39H36C36.5202 37.9542 36.5533 36.0287 36 35C35.5287 34.0356 35.0641 34.1412 34 34H26L37 15C37.3369 14.4129 37.174 13.6541 37 13C36.8269 12.3447 36.5884 11.3363 36 11C34.8933 10.3627 32.7813 10.9898 32 12L31 14L30 12C29.5397 11.2216 28.9043 11.002 28 11C27.6111 11.0014 27.3498 10.83 27 11C25.8057 11.5917 24.506 12.762 25 14V15L28 19L20 34H13Z"
      fill={fillColor}
    />
  </svg>
)
