import React from 'react'

import ContainerView from './view'
import { Navigate, useParams } from 'react-router-dom'
import { getProjectById } from '../../assets/data/projects'
import ProjectContent from '../../components/ProjectContent'

const ProjectScreen = () => {
  const { id } = useParams()

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    })
  }, [])

  if (!id) {
    return <>404</>
  }

  const project = getProjectById(id)
  return (
    <ContainerView>
      {(id => {
        if (id === 'cExpense' || id === 'WellCall' || id === 'cApprove' || id === 'Nice') {
          return <ProjectContent project={project} />
        } else {
          return <Navigate to="/" />
        }
      })(id)}
    </ContainerView>
  )
}

export default ProjectScreen
