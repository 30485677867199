import React from 'react'

export const GooglePlayLogo = ({ width = '60', height = '67', fillColor = '#061F3F' }) => (
  <svg width={width} height={height} viewBox="0 0 60 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99993 66C5.387 66.01 4.57436 66.2186 3.99993 66L32.9999 36L42.9999 46L9.99993 65C8.94645 65.6305 7.2207 65.9711 5.99993 66ZM1.99993 64C1.4116 62.9119 0.966867 61.2424 0.99993 60V6C0.995074 5.1809 0.756989 4.78064 0.99993 4L30.9999 34L1.99993 64ZM45.9999 44L34.9999 34L46.9999 22L56.9999 28C58.9196 28.8773 59.8694 30.8565 59.9999 33C59.87 35.1457 58.9221 37.1231 56.9999 38L45.9999 44ZM32.9999 32L2.99993 1C3.82278 0.378672 4.97655 0.000744793 5.99993 0C7.20856 0.0438235 7.95592 0.376617 8.99993 1L43.9999 21L32.9999 32Z"
      fill={fillColor}
    />
  </svg>
)
