import { SolutionCard } from 'components'
import React from 'react'
import { withTranslation } from 'react-i18next'
import user_experience from 'assets/gif/icon-UX.gif'
import user_interface from 'assets/gif/icon-ui.gif'
import interactive_prototyping from 'assets/gif/icon-prototyping.gif'
import mobile_dev from 'assets/gif/icon-mobile-dev.gif'
import db_backend from 'assets/gif/backend.gif'
import web_frontend from 'assets/gif/icon-web.gif'
import automatic_and_manual_qa from 'assets/gif/icon-QA.gif'
import project_management from 'assets/gif/PM.gif'
import { useGlobalContext } from 'context'

const ContainerView = ({ t }) => {
  const { SpecializationSection } = useGlobalContext()
  return (
    <section className="wwd" ref={SpecializationSection}>
      <div className="is-dark-bckg">
        <div className="container">
          <h2 className="title is-underline">{t('what_we_do_section.what_we_do')}</h2>

          <div className="lego">
            <div className="lego__item">
              <img className="lego__img" src={user_experience} alt="user experience" />
              <span className="lego__name">{t('what_we_do_section.user_experience')}</span>
            </div>
            <div className="lego__item">
              <img className="lego__img" src={user_interface} alt="user interface" />
              <span className="lego__name">{t('what_we_do_section.user_interface')}</span>
            </div>
            <div className="lego__item">
              <img className="lego__img" src={interactive_prototyping} alt="interactive prototyping" />
              <span className="lego__name">{t('what_we_do_section.interactive_prototyping')}</span>
            </div>
            <div className="lego__item">
              <img className="lego__img" src={mobile_dev} alt="mobile dev" />
              <span className="lego__name">{t('what_we_do_section.mobile_dev')}</span>
            </div>
            <div className="lego__item">
              <img className="lego__img" src={db_backend} alt="db backend" />
              <span className="lego__name">{t('what_we_do_section.db_backend')}</span>
            </div>
            <div className="lego__item">
              <img className="lego__img" src={web_frontend} alt="web frontend" />
              <span className="lego__name">{t('what_we_do_section.web_frontend')}</span>
            </div>
            <div className="lego__item">
              <img className="lego__img" src={automatic_and_manual_qa} alt="automatic and manual qa" />
              <span className="lego__name">{t('what_we_do_section.automatic_and_manual_qa')}</span>
            </div>
            <div className="lego__item">
              <img className="lego__img" src={project_management} alt="project management" />
              <span className="lego__name">{t('what_we_do_section.project_management')}</span>
            </div>
          </div>
          <p className="wwd__desc">{t('what_we_do_section.description_1')}</p>
          <p className="wwd__desc">{t('what_we_do_section.description_2')}</p>
          <p className="wwd__desc">{t('what_we_do_section.description_3')}</p>
          <h2 className="title is-underline is-offset">{t('what_we_do_section.how_it_works')}</h2>
        </div>
      </div>
      <div className="is-light-bckg">
        <div className="container">
          <div className="wwd__cards">
            <SolutionCard title={t('what_we_do_section.card_title_1')} descr={t('what_we_do_section.card_descr_1')} />
            <SolutionCard title={t('what_we_do_section.card_title_2')} descr={t('what_we_do_section.card_descr_2')} />
            <SolutionCard title={t('what_we_do_section.card_title_3')} descr={t('what_we_do_section.card_descr_3')} />
            <SolutionCard title={t('what_we_do_section.card_title_4')} descr={t('what_we_do_section.card_descr_4')} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(ContainerView)
