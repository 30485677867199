import { Circles } from 'components/ProjectsSection/circles'
import React from 'react'
import { Link } from 'react-router-dom'

const ContainerView = ({ id, src, title, desc }) => {
  return (
    <Link to={`project/${id}`} className="project">
      <h3 className={`title is-underline`}>{title}</h3>
      <p className={`project__desc`}>{desc}</p>
      <Circles place={id} />
      <div className={`project__img-box`}>
        <img src={src} alt={title} className="project__img" />
      </div>
    </Link>
  )
}

export default ContainerView
