import { AppProvider } from './context'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CareersScreen, MainScreen, ProjectScreen } from './screens'
import { Page404 } from 'screens/404/404'

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <Routes>
          <Route path="/" exact element={<MainScreen />} />
          <Route path="/careers" element={<CareersScreen />} />
          <Route path="/project/:id" element={<ProjectScreen />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </AppProvider>
    </BrowserRouter>
  )
}

export default App
