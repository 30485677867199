import React from 'react'
import { withTranslation } from 'react-i18next'
import { ProjectItem } from 'components'
import { useGlobalContext } from 'context'
import { mainScreenProjects as projects } from '../../assets/data/projects'
import { isEven } from '../../utils/isEven'
import { useWindowSize } from 'hooks/useWindowSize'

const ContainerView = ({ t }) => {
  const [width] = useWindowSize()

  const { ProjectsSection } = useGlobalContext()
  return (
    <section className="projects" ref={ProjectsSection}>
      <div className="container">
        <h2 className="title is-underline">{t('projects.title')}</h2>
        <div className="projects__wrap">
          {projects.map(({ id, pathImg, pathImgM, title, desc }, index) => (
            <ProjectItem
              key={`${id}`}
              id={id}
              src={width > 576 ? pathImg : pathImgM}
              title={t(title)}
              desc={t(desc)}
              reverse={isEven(index + 1)}
            />
          ))}
          <a name="Clients"></a>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(ContainerView)
