import React from 'react'

const ContainerView = () => (
  <>
    <a name="Contacts"></a>
    <div className="footer">
      <div>
        <img src="" alt="" />
      </div>
      <div className="footer__container container">
        <h2 className="footer__title is-underline">Many ways to contact us</h2>

        <div className="footer__nav">
          <a target="_self" href="mailto:boris@letme-agency.com" className="footer__nav-item">
            <div className="icon-Mail "></div>
            Email
          </a>

          <a className="footer__nav-item" target="_blank" href="http://wa.me/+972529257663" rel="noreferrer">
            <div className="icon-WhatsApp footer__svg"></div>
            WhatsApp
          </a>

          <a className="footer__nav-item" target="_self" href="skype:boris_shapiro?call">
            <div className="icon-Skype footer__svg"></div>
            Skype call
          </a>

          <a className="footer__nav-item" target="_self" href="tel:+972529257663">
            <div className="icon-Call footer__svg"></div>
            Phone call
          </a>
        </div>
        <div className="footer__nav footer__nav-icons">
          <a
            target="_blank"
            href="https://www.facebook.com/letmeagency"
            className="footer__socials icon-Facebook"
            rel="noreferrer"></a>
          <a
            target="_blank"
            href="https://www.instagram.com/letmeagency"
            className="footer__socials icon-Instagram"
            rel="noreferrer"></a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/let-me-agency"
            className="footer__socials icon-LinkedIn"
            rel="noreferrer"></a>
        </div>
      </div>
    </div>
  </>
)

export default ContainerView
