import React from 'react'

export const ChevronRightSmall = () => (
  <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="2.19588"
      y1="1.03131"
      x2="6.6627"
      y2="5.49813"
      stroke="#061F3F"
      strokeWidth="1.94811"
      strokeLinecap="round"
    />
    <line
      x1="6.66272"
      y1="5.49326"
      x2="2.1959"
      y2="9.96008"
      stroke="#061F3F"
      strokeWidth="1.94811"
      strokeLinecap="round"
    />
  </svg>
)
