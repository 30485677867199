import React from 'react'
import { withTranslation } from 'react-i18next'

const ContainerView = ({ t }) => (
  <section className="intro">
    <div className="container">
      <div className="intro__logo-wrap">
        <div className="intro__logo"></div>
      </div>
      <div className="intro__content">
        <h1 className="title">{t('intro_section.design_and_development')}</h1>
        <p className="intro__desc is-underline">{t('intro_section.design_and_development_descr')}</p>
        <p className="intro__desc2">{t('intro_section.design_and_development_descr2')}</p>
      </div>
    </div>
    <a name="Specialisation"></a>
  </section>
)

export default withTranslation()(ContainerView)
