import React from 'react'
import {
  MainLayout,
  IntroSection,
  WhatWeDoSection,
  TechnologySection,
  AboutSection,
  TeamSection,
  ProjectsSection,
  ClientsSection,
  FeedbackSection,
} from 'components'
import { withTranslation } from 'react-i18next'

const ContainerView = () => (
  <MainLayout>
    <IntroSection />
    <WhatWeDoSection />
    <TechnologySection />
    <AboutSection />
    <TeamSection />
    <ProjectsSection />
    <ClientsSection />
    <FeedbackSection />
  </MainLayout>
)

export default withTranslation()(ContainerView)
