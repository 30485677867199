import React from 'react'

export const RedLine = () => (
  <svg
    width="4"
    height="28"
    style={{ marginLeft: '17px', marginRight: '14px' }}
    viewBox="0 0 4 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <line
      x1="2.15175"
      y1="26.1"
      x2="2.15175"
      y2="2.28781"
      stroke="#E63946"
      strokeWidth="2.99874"
      strokeLinecap="round"
    />
  </svg>
)
