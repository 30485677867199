import React from 'react'
// import { withTranslation } from 'react-i18next';

import ContainerView from './view'

const CareersScreen = () => {
  return <ContainerView />
}

export default CareersScreen
