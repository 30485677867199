import { useState, useLayoutEffect } from 'react'
export const useWindowScrollY = () => {
  const [size, setSize] = useState([0])

  useLayoutEffect(() => {
    const updateSize = () => setSize([window.scrollY])

    window.addEventListener('scroll', updateSize)

    updateSize()

    return () => window.removeEventListener('scroll', updateSize)
  }, [])

  return size
}
