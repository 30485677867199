import { useWindowSize } from 'hooks/useWindowSize'
import React from 'react'
import s from './Circles.module.css'

export const Circles = ({ place }) => {
  const [width] = useWindowSize()
  window.addEventListener(
    'scroll',
    () => {
      document.body.style.setProperty(
        '--scroll',
        window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
      )
    },
    false
  )

  return (
    <div className="circles">
      <div className={`circle circle__1 circle__1__${place} ${width >= 576 ? s.move : s.move__mobile}`} />
      <div
        className={`circle circle__2 circle__2__${place} ${width >= 576 ? s.move_reverse : s.move_reverse__mobile}`}
      />
      <div className={`circle circle__3 circle__3__${place} ${width >= 576 ? s.move : s.move__mobile}`} />
    </div>
  )
}
