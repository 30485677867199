import React from 'react'

export const SingleTech = ({ title, img, index }) => {
  const special = index => `sp-${index}`

  return (
    <div className={`single-tech`}>
      <div className={`single-tech__container ${special(index)}`}>
        <div className="single-tech__img-box">
          <img src={img} alt={title} />
        </div>
        <p className="single-tech__title">{title}</p>
      </div>
    </div>
  )
}
