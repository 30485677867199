import { useWindowSize } from 'hooks/useWindowSize'
import React from 'react'
import { ArrowButton } from '../ArrowButton'
import ContainerView from './view'

const FeedbackSection = () => {
  const [width] = useWindowSize()
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: (() => width > 768)(),
    infinite: true,
    touchThreshold: 20,
    prevArrow: <ArrowButton />,
    nextArrow: <ArrowButton />,
  }
  return <ContainerView settings={settings} />
}

export default FeedbackSection
