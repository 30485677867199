import React from 'react'

export const MobileLogo = () => (
  <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_65_40)" filter="url(#filter0_d_65_40)">
      <path d="M61.5941 1.99999L0.72998 11.6399L10.3699 72.5041L71.234 62.8641L61.5941 1.99999Z" fill="#F0505C" />
      <path d="M12.1072 18.6003L19.2577 17.4678L22.753 39.5364L15.6026 40.6689L12.1072 18.6003Z" fill="white" />
      <path
        d="M40.8652 28.5525L41.1011 30.0422L31.5672 31.5522C31.5672 31.5522 32.1246 33.1437 34.5081 32.7662C35.4969 32.5328 36.3734 31.962 36.9866 31.152L40.1561 34.773C38.7056 36.6147 36.5989 37.8239 34.277 38.1474C29.212 38.9496 24.898 35.8153 24.1666 31.1973C23.8161 28.9848 24.359 26.7237 25.6756 24.9115C26.9923 23.0992 28.975 21.8842 31.1875 21.5338C33.4 21.1834 35.6611 21.7262 37.4733 23.0429C39.2856 24.3596 40.5006 26.3422 40.851 28.5547L40.8652 28.5525ZM33.6357 28.3595C33.4469 27.1678 32.7567 26.6663 32.0118 26.7843C31.267 26.9022 30.7655 27.5925 30.9542 28.7842L33.6357 28.3595Z"
        fill="white"
      />
      <path
        d="M42.0481 26.38L41.1043 20.4212L43.1899 20.0909L42.3877 15.026L49.524 13.8957L50.3262 18.9606L53.0076 18.5359L53.9514 24.4947L51.2699 24.9194L51.671 27.4518C51.8598 28.6436 52.55 29.1451 53.4438 29.0035C53.8491 28.9352 54.2459 28.8236 54.6273 28.6706L55.5711 34.6294C54.7222 34.9662 53.8388 35.2085 52.9368 35.352C48.4678 36.0598 45.3617 33.8031 44.5359 28.5892L44.1337 26.0496L42.0481 26.38Z"
        fill="white"
      />
      <path
        d="M41.7886 49.7631L43.1132 58.1266L35.9628 59.2592L34.6381 50.8957C34.4494 49.7039 33.7591 49.2024 33.1561 49.2979C32.5532 49.3934 32.0588 50.0826 32.2475 51.2743L33.5722 59.6378L26.4075 60.7726L25.0829 52.4091C24.8941 51.2173 24.2039 50.7158 23.608 50.8102C23.0121 50.9046 22.5106 51.5948 22.6994 52.7866L24.024 61.1501L16.8877 62.2804L14.3834 46.4684L21.2359 45.3831L22.1149 47.0764C22.4447 46.2978 22.9737 45.6198 23.6488 45.1108C24.3239 44.6018 25.1213 44.2796 25.9605 44.1767C28.9399 43.7048 30.8789 46.3064 30.8789 46.3064C30.8789 46.3064 32.2172 43.1857 35.2036 42.7127C38.1901 42.2397 40.9156 44.2513 41.7886 49.7631Z"
        fill="white"
      />
      <path
        d="M60.6222 47.2383L60.8581 48.728L51.3241 50.238C51.3241 50.238 51.8816 51.8295 54.2651 51.452C55.2594 51.221 56.1414 50.6491 56.7578 49.8355L59.9273 53.4566C58.4768 55.2983 56.37 56.5075 54.0481 56.831C48.9832 57.6332 44.6692 54.4989 43.9377 49.8808C43.5873 47.6684 44.1301 45.4073 45.4468 43.595C46.7635 41.7828 48.7462 40.5678 50.9587 40.2174C53.1712 39.8669 55.4322 40.4098 57.2445 41.7264C59.0568 43.0431 60.2717 45.0258 60.6222 47.2383ZM53.4139 47.042C53.2252 45.8502 52.5349 45.3487 51.7901 45.4667C51.0452 45.5847 50.5437 46.2749 50.7325 47.4667L53.4139 47.042Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_65_40"
        x="0.383785"
        y="0.269022"
        width="78.1204"
        height="78.1204"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3.46196" dy="2.07717" />
        <feGaussianBlur stdDeviation="1.90408" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.875 0 0 0 0 0.875 0 0 0 0 0.875 0 0 0 1 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_65_40" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_65_40" result="shape" />
      </filter>
      <clipPath id="clip0_65_40">
        <rect width="61.6228" height="61.6228" fill="white" transform="translate(0.72998 11.6399) rotate(-9)" />
      </clipPath>
    </defs>
  </svg>
)
