import React from 'react'
export const FeedbackItem = ({ person }) => {
  return (
    <div className="feedback-card">
      <div className="feedback-card__container">
        <div className="feedback-card__svg-box">
          <div className="icon-QMarks"></div>
        </div>
        <div className="feedback-card__person">
          <div className="feedback-card__photo">
            <img src={person.photo} alt={person.name} />
          </div>
          <div className="feedback-card__info">
            <p className="feedback-card__name is-underline">{person.name}</p>
            <p className="feedback-card__position">{person.position}</p>
            <p className="feedback-card__place">{person.place}</p>
          </div>
        </div>
        <div className="feedback-card__testimonials">
          {person.message.map((paragraph, index) => {
            return (
              <div key={`${paragraph.slice(0, 3)}-${index}`} className="feedback-card__paragraph">
                {paragraph}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
